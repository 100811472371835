th.rd-day-head {
  padding: 0 8px;
}

.rd-container {
  display: none;
  border: 1px solid $foodmenu-desc-color;
  background-color: #fff;
  padding: 10px;
  text-align: center;
  color: $foodmenu-desc-color;
  z-index: 100000;
}

.rd-day-selected, .rd-time-selected, .rd-time-option:hover {
  cursor: pointer;
  background-color: $link-color;
  color: #fff;
}

.rd-time-list {
  position: relative;
}

.rd-time {
  position: relative;
  display: inline-block;
  margin-top: 5px;
  min-width: 130px;
  font-size: 16px;
}