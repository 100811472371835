.slick-slider {
  margin-bottom: 0;
}
.slick-slide {
  outline: none;
}
.slick-prev, .slick-next {
  width: 45px;
  height: 45px;
  z-index: 1;
  opacity: 0.6;
}
.slick-prev {
  left: -50px;
  background: url(../images/icons/slider-arrow_prev.png);
}
.slick-next {
  right: -50px;
  background: url(../images/icons/slider-arrow_next.png);
}
.slick-prev:before, .slick-next:before {
  content: none;
}
.slick-prev:hover, .slick-next:hover {
  opacity: 1;
}
.slider-for .slick-slide {
  position: relative;
}
.slider-nav {
  margin-bottom: 100px;
}
.slider-nav .slick-slide {
  cursor: pointer;
  position: relative;
}
.slider-nav .slick-slide:hover:before {
  @extend .before;
  border: 2px solid orange;
  outline: none;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.slider-text {
  position: absolute;
  bottom: -100px;
  text-align: center;
  font-size: 20px;
  color: $text-color;
  font-family: Lato-Regular, sans-serif;
}
