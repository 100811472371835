/*Vendor stylesheets*/

/*Custom stylesheets*/
@import "parts/variable";
@import "parts/helpers";
@import "../fonts/fonts";

html {
	height: 100%;
}

body {
	margin:0;
	padding:0;
	height:100%;
	position:relative;
}

.page {
	min-height:100%;
	height:100%;
	height: auto !important;
	padding-bottom: 275px;
	&__header {
		min-height: 720px;
		height: 100vh;
		background-position: center top;
		background-size: cover;
		background-repeat: no-repeat;
		&--inner {
			min-height: 90px;
			height: 90px;
			padding-top: 0 !important;
			& .header {
				&__top {
					border-bottom-color: #e8e3dd;
				}
			}
			& .main-menu {
				& a {
					color: $menu-inverse-color;
					&:hover, &:focus, &:active {
						color: $link-color;
						border-bottom: 1px solid $link-color;
					}
				}
			}
			& .language {
				color: $menu-inverse-color;
				&:hover, &:focus, &:active {
					color: $link-color;
					border-bottom: 1px solid $link-color;
				}
			}
			& .button {
				display: inline-block;
				text-transform: none;
				margin-left: 20px;
				padding: 10px 15px;
				letter-spacing: normal;
			}
		}
	}
	&__footer {
		margin-top: -270px;
		padding-top: 60px;
		background: $footer-color;
	}
}

.header {
	padding-top: 32px;
	&__top {
		position: relative;
		z-index: 1;
		height: 90px;
		border-bottom: 1px solid $menu-opacity-color;
	}
}

.main-menu, .footer-menu {
	display: block;
	&__list {
		display: block;
		height: 90px;
		margin: 0;
		padding: 0;
	}
	&__item {
		display: inline-block;
		margin: 0 18px;
	}
	& a	{
		line-height: 90px;
		font-family: 'AmericaXIX', sans-serif;
		font-size: $font-size;
		color: $menu-opacity-color;
		border: none;
		&:hover, &:focus, &:active {
			color: $menu-color;
			text-decoration: none;
			border-bottom: 1px solid $menu-opacity-color;
		}
	}
}

.language {
	font-family:'Lato-Regular', sans-serif;
	color: $menu-opacity-color;
	border: none;
	&:hover, &:focus, &:active {
		color: $menu-color;
		text-decoration: none;
		border-bottom: 1px solid $menu-opacity-color;
	}
}

.footer-menu {
	&__item {
		margin: 0 40px 0 0;
	}
	& a {
		color: $footer-menu-color;
		&:hover, &:focus, &:active {
			color: $footer-menu-color;
			text-decoration: none;
			border-bottom: 1px solid $footer-menu-color;
		}
	}
}

.copyright {
	position: relative;
	padding: 0;
	border: none;
	font-family: 'Lato-Regular',sans-serif;
	font-size: $font-size;
	line-height: 90px;
	color: $footer-menu-color;
	&:hover {
		color: $footer-menu-color;
		text-decoration: none;
		padding-bottom: 4px;
		border-bottom: 1px solid $footer-menu-color;
		&:before {
			transform: rotate(360deg);
		}
	}
	&:before {
		@extend .before;
		left: -30px;
		top: 50%;
		margin-top: -12px;
		width: 24px;
		height: 24px;
		background: url(../images/icons/yakubbo.png) center no-repeat;
		transition: all 0.4s linear;
	}
}

.logo {
	display: block;
	height: 90px;
	border: none;
	&>img {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
	}
	&__main {
		margin: 50px auto 10px auto;
	}
	&__desc {
		margin: 0;
		font-family: 'AmericaXIX', sans-serif;
		font-size: $font-size*2;
		color: $menu-color;
	}
	&__btn {
		margin-top: 90px;
	}
}

.button {
	display: inline-block;
	padding: 12px 34px 10px 34px;
	background: $btn-color;
	border:none;
	text-align: center;
	font-family: 'Lato-Regular', sans-serif;
	font-size: $font-size;
	color: $menu-color;
	text-transform: uppercase;
	letter-spacing: 2px;
	outline: none;
	&:hover {
		color: $menu-color;
		text-decoration: none;
		background: lighten($btn-color,10%);
		cursor: pointer;
	}
}

.language {
	margin-left: 30px;
	line-height: 90px;
}

.footer-contacts {
	border-bottom: 1px solid $footer-menu-color;
	padding-bottom: 20px;
	&__title {
		font-family: 'Lato-Heavy',sans-serif;
		font-size: $font-size;
		color: $menu-color;
		margin-bottom: 10px;
		&:before {
			@extend .before;
			width: 16px;
			height: 16px;
			left: -10px;
			top: 2px;
			background-position: center center;
			background-repeat: no-repeat;
		}
		&--marker {
			&:before {
				background-image: url(../images/icons/marker.png);
			}
		}
		&--time {
			&:before {
				background-image: url(../images/icons/time.png);
			}
		}
		&--social {
			&:before {
				background-image: url(../images/icons/social.png);
			}
		}
	}
	&__desc {
		font-family: 'Lato-Regular', sans-serif;
		font-size: $font-size;
		color: $menu-color;
		&>span {
			font-family: 'Lato-Italic', sans-serif;
		}
	}
	&__language {
		color: $footer-menu-color;
		border: none;
		&:hover {
			color: $footer-menu-color;
			border-bottom: 1px solid $footer-menu-color;
		}
	}
}

.social {
	&__item {
		display: inline-block;
		margin: 0 10px 0 0;
		width: 32px;
		height: 32px;
		border: 1px solid rgba(255,255,255,0.2);
		background-repeat: no-repeat;
		background-image: url(../images/icons/social_icons.png);
		&:hover {
			background-color: $link-color;
		}
		&--fb {
			background-position: -2px center;
		}
		&--vk {
			background-position: right -3px center;
		}
	}
}

.menu {
	padding: 60px 0;
	&--bg {
		background: url(../images/menu-bg.png) center center no-repeat;
		background-size: cover;
	}
	&-wrapper {
		background: white;
		padding: 30px;
		& .menu__container {
			padding: 40px 60px;
		}
	}
	&__container {
		position: relative;
		min-height: 100px;
		border-image: url(../images/menu-top-border.png) 87 round;
		border-top: 34px solid;
		border-bottom: 34px solid;
		padding: 40px 80px;
		&:before {
			@extend .before;
			width: 26px;
			top: 0;
			bottom: 0;
			left: 0;
			background: url(../images/menu-border.png) left top;
		}
		&:after {
			@extend .before;
			position: absolute;
			width: 26px;
			top: 0;
			bottom: 0;
			right: 0;
			background: url(../images/menu-border.png) right top;
		}
	}
	&__file {
		margin: 20px 0;
		border: none;
		&>img {
			margin-right: 10px;
			float: left;
		}
	}
	&__filename {
		margin-bottom: 7px;
		padding-bottom: 3px;
		color: $link-color;
		border-bottom: 1px solid $underline-color;
	}
}

.pdf {
	display: inline-block;
	margin-top: 5px;
	color: $link-color;
	font-family: 'Lato-Italic', sans-serif;
	border: none;
}

.foodmenu-item {
	margin: 20px  0 0 0;
	padding: 0 0 20px 0;
	border-bottom: 1px solid $foodmenu-separate-color;
	&__title {
		font-family:'Lato-Heavy', sans-serif;
		font-size: $font-size;
		color: $foodmenu-item-color;
		text-transform: uppercase;
		padding-bottom: 10px;
		&>span {
			font-family:'Lato-Regular', sans-serif;
		}
	}
	&__desc {
		font-family:'Lato-Regular', sans-serif;
		font-size: $font-size;
		color: $foodmenu-desc-color;
		&>span {
			font-family: 'Lato-Italic', sans-serif;
			color: $foodmenu-weight-color;
		}
	}
}

.open {
	position: relative;
	height: auto;
	padding: 120px 0;
	&__title {
		padding-top: 4%;
	}
	&__content {
		padding: 60px 0 0 0;
		&>img {
			margin-left: 30px;
		}
		&>p {
			margin-bottom: 30px;
		}
	}
}

.slogan	{
	min-height: 512px;
	background: url(../images/slogan_bg.jpg) center repeat-x;
	&__overlay {
		min-height: 512px;
		padding: 120px 0 0 0;
		background: url(../images/slogan-images.png) center top no-repeat;
	}
	&__text {
		font-family: 'AmericaXIX', sans-serif;
		font-size: $font-size*2;
		color: $slogan-color;
		margin-bottom: 60px;
	}
}
.interier {
	padding: 60px 0;
	&__img {
		margin: 30px 0;
	}
}

.places {
	display: inline-block;
	margin: 20px auto;
	padding: 15px 0;
	text-align: center;
	font-family: 'Lato-Italic', sans-serif;
	font-size: $font-size;
	color: #b94100;
	border-top: 1px solid #f1d9cc;
	border-bottom: 1px solid #f1d9cc;
	&__big {
		font-family: 'AmericaXIX', sans-serif;
		font-size: $font-size*2+2;
	}
}

.news {
	padding: 60px 0;
	&__item {
		padding: 30px;
		border: none;
		&:hover {
			& .news__title {
				color: $link-color;
				text-decoration: underline;
			}
		}
	}
	&__date {
		font-family: 'Lato-Italic', sans-serif;
		font-size: $font-size+4;
		color: #be925d;
	}
	&__img {
		margin: 10px 0;
	}
	&__title {
		font-family: 'Lato-Heavy', sans-serif;
		font-size: $font-size+5;
		color: #89583f;
	}
}

.reserve {
	padding: 60px 0;
	background: url(../images/form_bg.jpg) repeat-x center top;
	&__form {
		padding: 60px 60px;
		background: #fff;
	}
	&__phone {
		font-family: 'Lato-Heavy', sans-serif;
		font-size: $font-size*2+2;
		color: #b94100;
		line-height: 50px;
		&>sup {
			font-size: $font-size+4;
			margin-right: 8px;
			top: -0.4em;
		}
	}
}

.form {
	&__warning {
		font-family: 'Lato-Italic', sans-serif;
		font-size: $font-size;
		color: #be925d;
	}
	&-group {
		margin: 25px 0;
		p {
			margin: 0 !important;
		}
	}
	&-control {
		border-radius: 0;
		border-color: #f1e4c5;
		height: 40px;
		box-shadow: none;
		outline: none;
		padding-top: 10px;
		font-family: 'Lato-Regular',sans-serif;
		font-size:$font-size+4;
		color: #89583f;
		&:hover {
			border-color: #fcd18d;
		}
		&:focus,&:active {
			box-shadow: none;
			border-color: #f9a41b;
		}
	}
}

.icon-wrapper {
	&:before {
		@extend .before;
		width: 20px;
		height: 20px;
		right: 25px;
		bottom: 10px;
		pointer-events: none;
	}
	&--date {
		&:before{
			background: url(../images/icons/calendar.png) center center no-repeat;
		}
	}
	&--time {
		&:before{
			background: url(../images/icons/clock.png) center center no-repeat;
			right: 22px;
		}
	}
}

label {
	font-family: 'Lato-Heavy', sans-serif;
	font-size: $font-size;
	color: #be925d;
}

textarea.form-control {
	height: 126px;
	resize: none;
}

.category {
	padding: 5px;
	border: 4px solid #f1e4c5;
	&__list {
		padding: 10px;
		margin: 0;
		list-style: none;
		border: 1px solid #f1e4c5;
	}
	&__item {
		display: block;
		padding: 5px 0;
		&>a {
			font-family: Lato-Heavy, sans-serif;
			border: none;
		}
		&.current-menu-item {
			background: white;
			margin: 0 -40px 0 -20px;
			padding: 20px 40px 20px 20px;
			border: 2px solid #f1e4c5;
			border-bottom-right-radius: 20px 30px;
			border-top-right-radius: 20px 30px;
			&>a {
				color: #be925d;
			}
		}
	}
}

.downloads_menu {
	margin: 20px 0;
	&__title {
		margin-bottom: 20px;
		font-family: AmericaXIX, sans-serif;
		font-size: 20px;
		color: #be925d;
		&>span {
			font-family: Lato-Italic, sans-serif;
			font-size: 16px;
		}
	}
	&__link {
		display: inline-block;
		font-family: Lato-Regular, sans-serif;
		color: #be925d;
		border-bottom-color: #be925d;
		margin-right: 30px;
		margin-bottom: 10px;
		&>i {
			font-family: Lato-Italic, sans-serif;
		}
	}
}

.breadcrumb {
	background: none;
	color: $link-color;
	padding: 15px 0 0 0;
	margin: 0;
	& a {
		font-family: 'Lato-Regular', sans-serif;
		font-size: $font-size;
		border-bottom: none;
		margin: 0 10px;
	}
	& span {
		color: $menu-inverse-color;
		font-size: $font-size;
		margin: 0 10px;
	}
}

.item-news {
	margin-bottom: 20px;
	& h1 {
		@extend .h2
	}
	&__title {
		margin: 15px 0 20px 0;
		font-family: 'AmericaXIX', sans-serif !important;
		font-size: $font-size * (46/16) !important;
		color: $h2-color !important;
	}
}

.gallery {
	&__title {
		margin: 30px 0;
	}
}
#map {
	position: absolute;
	width: 100%;
	top: 0;
	bottom: 0;
	left: 0;
}

.icons {
	width: 32px;
	top: 0;
	bottom: 0;
	left: 0;
}

.contacts {
	background: #fff;
	padding: 25px 43px;
	position: relative;
	&__item {
		position: relative;
		overflow: hidden;
		margin: 30px 0;
	}
	&__icon {
		&--mobile {
			&:before {
				@extend .before;
				@extend .icons;
				background: url("../images/icons/c_phone.png") center center no-repeat;
			}
		}
		&--mark {
			&:before {
				@extend .before;
				@extend .icons;
				background: url("../images/icons/c_marker.png") center center no-repeat;
			}
		}
		&--mail {
			&:before {
				@extend .before;
				@extend .icons;
				background: url("../images/icons/c_mail.png") center center no-repeat;
			}
		}
		&--clock-small {
			&:before {
				@extend .before;
				@extend .icons;
				background: url("../images/icons/c_clock.png") center center no-repeat;
			}
		}
	}
	&__desc {
		padding-left: 50px;
		font-family: Lato-Regular, sans-serif;
		font-size: 20px;
		color: $text-color;
		&>span {
			color: $text-color;
		}
	}
}

.feedback {
	padding: 40px 0;
	background: url(../images/form_bg.jpg) repeat-x center top;
	&__form {
		background: #fff;
		padding: 20px 40px;
	}
	&__title {
		margin-bottom: 40px;
	}
	&__comment {
		margin-top: 20px;
		font-size: $font-size;
		font-family: AmericaXIX, sans-serif;
	}
	&__textarea {
		height: 132px;
		margin-bottom: 30px;
	}
	&__success {
		& p {
			margin: 20px 0 !important;
		}
	}
}

@import "parts/datapicker";
@import "parts/form";
@import "parts/sliders";
@import "parts/city-select";
@import "parts/responsive";