/*Add style for responsive view*/

/*@media screen (min-width: #WIDTH#px) {

}*/

@media screen and (max-width: 600px) {

  .page {
    &__header {
      min-height: 0;
      padding-top: 0;
      &--inner {
        height: 190px !important;
        & .sandwitch {
          border-bottom-color: $text-color;
          &:before {
            background: $text-color;
          }
          &:after {
            background: $text-color;
          }
          &--open {
            border-bottom-color: transparent;
            &:before {
              background: white;
              transform: rotate(45deg);
              top: 12px;
            }
            &:after {
              background: white;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
    &__footer {
      padding: 20px;
      text-align: center;
    }
  }

  .header {
    height: 610px;
    &__top {
      & .button {
        display: block;
        float: none;
        margin: 60px auto 0 auto !important;
      }
    }
  }

  .small-menu {
    color: white;
    border: none;
    margin-top: 10px;
  }

  .sandwitch {
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
    width: 40px;
    height: 30px;
    position: absolute;
    top: 30px;
    right: 10px;
    margin-top: -15px;
    border-bottom: 6px solid white;
    opacity: 0.5;
    z-index: 2;
    transition: all 0.2s linear;
    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: 6px;
      top: 0;
      left: 0;
      background: white;
      transition: all 0.2s linear;
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: 6px;
      top: 50%;
      left: 0;
      background: white;
      transition: all 0.2s linear;
    }
    &:hover {
      opacity: 1;
    }
    &--open {
      border-bottom-color: transparent;
      &:before {
        background: white;
        transform: rotate(45deg);
        top: 12px;
      }
      &:after {
        background: white;
        transform: rotate(-45deg);
      }
    }
  }


  .logo {
    width: 100px;
    display: block;
    margin: 0 auto 0 auto;
    &__main {
      width: 320px;
      margin-top: 30px;
    }
    &__desc {
      font-size: 20px;
      margin-bottom: 40px;
    }
  }
  
  .main-menu {
    display: none;
    position: absolute;
    text-align: center;
    padding-top: 20px;
    background: $text-color;
    z-index: 1;
    width: 100%;
    margin-left: -15px;
    &__list {
      margin-top: 0px;
      height: auto;
    }
    &__item {
      display: block;
      margin: 0;
      height: 30px;
      line-height: normal;
      &>a {
        line-height: normal;
      }
    }
  }

  .language {
    display: block;
    margin: 30px auto 0 auto;
    line-height: normal;
    text-align: center !important;
    &-small {
      font-size: 16px;
      color: white;
      border: none;
      line-height: 30px !important;
      margin-bottom: 10px;
      &:hover {
        border: none;
      }
    }
  }

  .h1 {
    font-size: $font-size*1.5;
    text-align: center;
    &>a {
      padding: 0;
    }
  }

  section {
    padding: 20px 0 !important;
  }

  .button {
    font-size: $font-size/1.5;
  }

  .open__content {
    padding: 20px 15px;
    & > img {
      display: none;
      float: none !important;
      margin: 0 auto;
    }
  }

  p {
    font-size: $font-size;
    & img {
      max-width: 100%;
    }
  }

  a {
    font-size: $font-size - 2;
  }

  .slogan {
    &__overlay {
      padding-top: 100px;
    }
    &__text {
      font-size: $font-size * 1.3;
      padding-top: 0;
    }
  }

  .menu {
    &__container {
      padding: 10px 40px;
    }
    & h1 {
      text-align: center;
    }
  }

  .places {
    width: 100%;
  }

  .news {
    &__item {
      overflow: hidden;
    }
    &__date {
      font-size: $font-size;
      text-align: center;
    }
    &__img {
      background-repeat: no-repeat !important;
      background-size: contain !important;
      background-position: center !important;
      &>img {
        display: block;
        margin: 0 auto;
      }
    }
    &__title {
      text-align: center;
    }
  }

  .reserve {
    &__form {
      padding: 20px;
      & .form {
        &__warning {
          font-size: $font-size - 2;
        }
      }
      & .button {
        margin: 20px 0 0 0;
        width: 100%;
      }
    }
    & .h1 {
      text-align: center;
    }
    &__phone {
      width: 100%;
      text-align: center;
      font-size: $font-size*1.4;
      &>sup {
        font-size: $font-size/1.2;
      }
    }
  }

  .footer-contacts {
    &__item {
      & img {
        display: inline-block;
      }
      margin-bottom: 30px;
    }
    &__title {
      margin-bottom: 5px;
      &:before {
        left: 50%;
        margin-left: -8px;
        top: -20px;
      }
    }
    &__language {
      display: block;
    }
  }

  .social {
    width: 100%;
  }

  .footer-menu {
    &__list {
      height: auto;
      margin: 30px 0 0 0;
    }
    &__item {
      display: block;
      line-height: normal;
      height: 30px;
      margin: 0;
      &>a {
        line-height: normal;
      }
    }
  }

  .copyright {
    width: 100%;
    text-align: center;
  }

  .contacts {
    &__desc {
      font-size: $font-size;
    }
  }

  .feedback {
    &__comment {
      font-size: $font-size - 2;
      margin-bottom: 15px;
      display: block;
    }
    &__form {
      padding: 20px;
      & .h2 {
        font-size: $font-size*1.4;
        text-align: center;
      }
    }
  }

  .form__warning {
    font-size: $font-size - 4;
  }

  .menu {
    &-wrapper {
      padding: 0;
      & .menu__container {
        padding: 20px 35px;
      }
      & .h1 {
        margin: 30px 0;
      }
    }

  }

  .slick-next {
    right: -15px;
  }

  .slick-prev {
    left: -15px;
  }

  .item-news {
    margin-bottom: 20px;
    &__title {
      font-size: $font-size*1.5 !important;
    }
    & p img {
      max-width: 100%;
    }
  }

  .alignright {
    display: block;
    float: none;
    margin: 10px auto;
  }

  .alignleft {
    display: block;
    float: none;
    margin: 10px auto;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
  }

  .show-more {
    margin-bottom: 30px;
  }

}