* {
  outline: none;
}
img {
  border: none;
}
.img-response {
  @extend .img-responsive;
  min-width: 100%;
  max-width: 100%;
}

.before {
  position: absolute;
  display: block;
  content: '';
}

.h1 {
  font-family: 'AmericaXIX', sans-serif;
  font-size: $font-size * (46/16);
  color: $h2-color;
  margin: 0;
  &>a {
    padding-top: 18px;
  }
}

.h2 {
  font-family: AmericaXIX, sans-serif;
  font-size: $font-size * 2;
  color: #b94100;
  margin: 20px 0;
}

p {
  font-family: 'Lato-Regular',sans-serif;
  font-size: $font-size + 4;
  color: $text-color;
}

a {
  padding-bottom: 5px;
  font-family: 'AmericaXIX', sans-serif;
  font-size: $font-size;
  color: $link-color;
  border-bottom: 1px solid $underline-color;
  &:hover, &:focus, &:active {
    color: $link-color;
    border-color: $link-color;
    text-decoration: none;
  }
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}

.aligncenter {
  margin: 0 auto;
}

.ml {
  margin-left: 30px;
}

.ruble {
  font-family: initial;
}