.city-select {
  width: 150px;
  $color: #fff;
  &--xs {
    position: absolute !important;
    top: 15px !important;
    z-index: 1 !important;
  }
  &.focused .jq-selectbox__select {
    border: 1px solid $color;
  }
  & .jq-selectbox {
    &__select {
      border-radius: 0;
      background: none;
      text-shadow: none;
      color: $color;
      box-shadow: none;
      border-color: $color;
      font-family: Lato-Regular, sans-serif;
    }
    &__dropdown {
      border-radius: 0;
      background: 0;
      border-color: $color;
      & li {
        color: #fff;
        font-family: Lato-Regular, sans-serif;
        &:hover {
          background-color: unset;
        }
        &.sel {
          background-color: $text-color;
        }
      }
    }
    &__trigger {
      border: none;
    }
    &__trigger-arrow {
      border-top-color: $color;
    }
  }
  &:hover {
    .jq-selectbox {
      &__trigger-arrow {
        border-top-color: $color;
      }
    }
  }
}
