.wpcf7-not-valid {
  border-color: #b94100;
}

.screen-reader-response {
  display: none;
}

.wpcf7-response-output.wpcf7-display-none.wpcf7-validation-errors {
  font-family: 'Lato-Heavy', sans-serif;
  font-size: 16px;
  color: #b94100;
}

span.wpcf7-not-valid-tip {
  display: none;
}

.wpcf7-response-output.wpcf7-display-none.wpcf7-mail-sent-ok {
  display: none !important;
}

form.wpcf7-form.sent {
  display: none;
}

.ok, .ok1 {
  & .h1 {
    margin: 30px 0;
  }
  & p {
    margin: 30px 0;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 768px;
    margin: 40px auto;
  }
}

.close {
  width: 32px;
  height: 32px;
  position: absolute;
  top: -16px;
  right: 0;
  background: url(../images/icons/close.png) center center no-repeat !important;
  opacity: 1;
  outline: none;
  &:hover {
    background: url(../images/icons/close_hover.png) center center no-repeat !important;
    opacity: 1;
  }
}